import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [{
        name: 'notFound',
        path: '/:path(.*)+',
        redirect: {
            path: '/wiki/search',
        },
    },
    {
        path: '/wiki/search',
        component: () => import('./view/wiki/search'),
        meta: {
            title: '搜索页',
        },
    },
    {
        path: '/wiki/detail/:id',
        component: () => import('./view/wiki/detail'),
        meta: {
            title: '详情页',
        },
    },
    {
        path: '/wiki/photo/:id',
        component: () => import('./view/wiki/photo'),
        meta: {
            title: '相册管理',
        },
    },
    {
        name: 'add',
        path: '/wiki/add',
        component: () => import('./view/wiki/add'),
        meta: {
            title: '添加品种',
        },
    },
    {
        path: '/wiki/edit/:id',
        component: () => import('./view/wiki/edit'),
        meta: {
            title: '修改信息',
        },
    },
    {
        name: 'recycle',
        path: '/wiki/recycle',
        component: () => import('./view/wiki/recycle'),
        meta: {
            title: '回收站',
        },
    },
];

const router = createRouter({
    routes,
    history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});

export { router };